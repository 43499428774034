import React from 'react';

import Slides from '../components/Slides';
import Stats from '../components/Stats';

const Home: React.FC = () =>
  <div>
    <Slides />
    <Stats />
  </div>

export default Home;
