import React from 'react';

import Container from 'react-bootstrap/Container'

// Components
import PageTitle from '../components/PageTitle';
import Service, { ServiceItem } from '../components/Service';

const Exams: React.FC = () => {
  const exams: ReadonlyArray<ServiceItem> = [
    {
      image: "audiometria",
      name: "Audiometría Clínica",
      paragraphs: [
        "La audiometría tonal es una prueba que permite evaluar con precisión " +
        "el funcionamiento del sistema auditivo. Nos aporta información sobre " +
        "la ubicación del problema y la posible causa de la pérdida auditiva. " +
        "También ayuda al profesional a definir el tratamiento a seguir."
      ]
    },
    {
      image: "logoaudiometria",
      name: "Logoaudiometría",
      paragraphs: [
        "También llamada audiometría verbal es un estudio de la audición cuyo " +
        "objetivo es determinar la capacidad de la persona para discriminar el " +
        "lenguaje hablado. Su principal aplicación clínica es la localización y " +
        "cuantificación de una disfunción en el sistema auditivo. Es imprescindible " +
        "en la adaptación de audífonos tanto para la selección de las características " +
        "del audífono como para la comprobación de la calidad de la adaptación."
      ]
    },
    {
      image: "impedanciometria",
      name: "Impedanciometría",
      paragraphs: [
        "La Impedanciometría es una prueba objetiva que mide la integridad y función " +
        "del mecanismo del oído medio. A través de ella se puede determinar: la presión " +
        "existente en el oído medio, la movilidad de la membrana timpánica, la función " +
        "de la trompa de Eustaquio, la movilidad y continuidad de los huesecillos de la " +
        "cadena osicular y los umbrales del reflejo acústico y su dinámica. Esta prueba " +
        "se compone de dos fases. La timpanometría, que analiza la movilidad del tímpano; " +
        "y el estudio del reflejo estapedial, es decir, la contracción del músculo del " +
        "estribo ante un sonido fuerte."
      ]
    },
    {
      image: "tamizaje",
      name: "Tamizaje Auditivo",
      paragraphs: [
        "La Audiometría Tamiz es una prueba que nos permite valorar la capacidad auditiva. " +
        "Establece el mínimo nivel de intensidad sonora que es percibido por cada oído " +
        "(umbral auditivo). El objetivo principal es detectar oportunamente posibles pérdidas " +
        "auditivas, con el fin de prevenir y evitar su progresión."
      ]
    },
    {
      image: "ocupacional",
      name: "Audiometría Ocupacional",
      paragraphs: [
        "Este tipo de exámen audiológico está orientado a evaluar la función auditiva de los " +
        "trabajadores expuestos al ruido. Se realiza con el fin de detectar los efectos que " +
        "han sufrido en su sistema auditivo y así, realizar un seguimiento de la efectividad " +
        "de las medidas de control implementadas en la empresa."
      ]
    },
  ]

  return(
    <div>
      <PageTitle text="Exámenes Auditivos" />
      <Container fluid="sm" className="px-0">
        {exams.map((e, idx) => (
          <Service
            key={idx}
            image={e.image}
            name={e.name}
            paragraphs={e.paragraphs}
            reverse={!!(idx % 2)}
          />
        ))}
      </Container>
    </div>
  );
}

export default Exams;
