import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Components
import ImageStarkey from '../components/ImageStarkey';
import PageTitle from '../components/PageTitle';

const DraAlina: React.FC = () => {
  const cvLink = (
    <a
      href={process.env.PUBLIC_URL + "/docs/hoja-de-vida-alina-margarita-diaz-sterling.pdf"}
      target="_blank"
      rel="noreferrer noopener"
    >
      Ver hoja de vida
    </a>
  );

  return(
    <div className="App-Dra-Alina">
      <PageTitle text="Dra. Alina Margarita Díaz Stérling" />
      <Container fluid="sm" className="px-0">
        <Row noGutters>
          <Col md className="image-alina-container">
            <div className="image-alina" />
          </Col>
          <Col md>
            <div className="text-block">
              <p>
                Fonoaudióloga egresada de la Universidad del Rosario,
                Especialista en Audiología de la Universidad Iberoamericana.
              </p>
              <p>
                Dedicada a la realización de exámenes audiológicos de diagnóstico,
                selección y adaptación de audífonos y protección auditiva, con más
                de 35 años de experiencia.
              </p>
              <p>
                Fundadora y propietaria del CENTRO AUDIOLÓGICO Y TERAPEUTICO DEL
                HUILA “Cenaudio” entidad reconocida a nivel departamental y nacional
                en su área.
              </p>
              <p>
                Distribuidora autorizada de las mejores marcas de audífonos que
                ofrecen soluciones efectivas a todas las personas con cualquier
                nivel de pérdida de audición en alguna etapa de la vida.
              </p>
              <div className="d-none d-sm-block">
                <ImageStarkey />
              </div>
              <p className="d-none d-sm-block mt-2">
                Durante su vida profesional ha recibido capacitación internacional
                en países como: Italia, España, Dinamarca, México y Estados Unidos.
              </p>
              <p className="d-none d-sm-block mb-0">
                Distribuidora de audífonos de alta tecnología marca Starkey
                Laboratories Inc., una compañía internacional de tecnología auditiva
                cuya casa matriz se encuentra ubicada en la ciudad de Minneapolis,
                Minnesota, Estados Unidos.
              </p>
              <p className="d-none d-sm-block mb-0 mt-4">
                {cvLink}
              </p>
            </div>
          </Col>
        </Row>

        <Row noGutters>
          <Col md className="d-md-none d-sm-none">
            <ImageStarkey />
          </Col>
          <Col md>
            <div className="text-block d-md-none d-sm-none">
              <p>
                Durante su vida profesional ha recibido capacitación internacional
                en países como: Italia, España, Dinamarca, México y Estados Unidos.
              </p>
              <p>
                Distribuidora de audífonos de alta tecnología marca Starkey
                Laboratories Inc., una compañía internacional de tecnología auditiva
                cuya casa matriz se encuentra ubicada en la ciudad de Minneapolis,
                Minnesota, Estados Unidos.
              </p>
              <p className="d-md-none d-sm-none">
                {cvLink}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DraAlina;
