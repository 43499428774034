import React from 'react';

// Images
import logo from './../images/logo.jpg'

const Header: React.FC = () =>
  <div className="App-Header">
      <img src={logo} alt="Cenaudio logo" />
  </div>

export default Header;
