import React from 'react';

import Container from 'react-bootstrap/Container'

// Components
import PageTitle from '../components/PageTitle';
import Service, { ServiceItem } from '../components/Service';

const Headphones: React.FC = () => {
  const headphones: ReadonlyArray<ServiceItem> = [
    {
      image: "cic",
      name: "Audífono CIC",
      paragraphs: [
        "Los audífonos CIC o Completamente en el canal son una opción muy " +
        "solicitada por los pacientes. Son fabricados con tecnología avanzada " +
        "y se recomiendan para pérdida auditiva de leve a moderadamente severa.",
        "Estos audífonos se hacen sobre medida y son muy discretos, estéticos, " +
        "cómodos y prácticamente imperceptibles, solo se muestra la pequeña manija " +
        "de remoción del audífono afuera del canal auditivo.",
      ]
    },
    {
      image: "intracanal",
      name: "Audífono Intracanal",
      paragraphs: [
        "Audífono hecho a la medida que se adapta en el canal auditivo. Se muestra " +
        "una pequeña porción del audífono en la parte externa del oído. ",
        "Se recomiendan para pérdida auditiva de leve a ligeramente severa y además " +
        "son una buena opción para personas con dificultades motoras y fallas de " +
        "visión, ya que no son tan pequeños como los CIC.",
        "Este modelo se ofrece en tecnología recargable y con conectividad inalámbrica " +
        "(celular, tv, etc), con sensores integrados e inteligencia artificial.",
      ]
    },
    {
      image: "retroauricular",
      name: "Audífono Retroauricular",
      paragraphs: [
        "El modelo Retroauricular (por detrás de la oreja) es muy práctico. Es el " +
        "estilo de audífono más común del mundo, y se recomienda especialmente para " +
        "pérdidas auditivas severas a profundas.",
        "Su diseño es discreto y tiene botones muy fáciles de utilizar. Este modelo " +
        "se ofrece en tecnología recargable y con conectividad inalámbrica (celular, " +
        "tv, etc), con sensores integrados e inteligencia artificial.",
      ]
    },
    {
      image: "ric",
      name: "Audifono RIC",
      paragraphs: [
        "Los audífonos RIC o con Receptor Intra Canal, son una de las opciones más " +
        "prácticas y cómodas del mercado, al ser más pequeños que los modelos retro se " +
        "caracterizan por ser muy discretos y livianos. Así mismo, generan una sensación " +
        "de audición más natural, evitando la sensación de taponamiento.",
        "Este modelo se ofrece en tecnología recargable y con conectividad inalámbrica " +
        "(celular, tv, etc), con sensores integrados e inteligencia artificial.",
      ]
    },
  ]

  return(
    <div>
      <PageTitle text="Prueba, Selección y Adaptación de Audífonos" />
      <Container fluid="sm" className="px-0">
        {headphones.map((h, idx) => (
          <Service
            key={idx}
            image={h.image}
            imageClass={"headphone"}
            name={h.name}
            paragraphs={h.paragraphs}
            reverse={!!(idx % 2)}
          />
        ))}
      </Container>
    </div>
  );
}

export default Headphones;
