import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Components
import PageTitle from '../components/PageTitle';

const Vertigo: React.FC = () =>
  <div className="App-Vertigo">
    <PageTitle text="Rehabilitación Vestibular" />
    <Container fluid="sm" className="px-0">
      <Row>
        <Col md>
          <div className="text-block">
            <p>
              Es un programa de rehabilitación que incluye la aplicación de una
              serie de ejercicios no invasivos, sin efectos secundarios; orientados
              principalmente a la compensación del sistema vestibular (sistema
              encargado del equilibrio), reduciendo paulatinamente la sensación
              vertiginosa, generando estabilidad y restableciendo la calidad de
              vida del paciente.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>

export default Vertigo;
