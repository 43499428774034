import React from 'react';

import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';

const Slides: React.FC = () =>
  <div className="App-Slides">
      <Carousel
        animationSpeed={1000}
        plugins={[
          {
            resolve: autoplayPlugin,
            options: {
              interval: 4000,
              stopAutoPlayOnHover: false,
            }
          },
          'infinite',
        ]}
      >
        <div className="slide slide-one">
          <p>La mejor experiencia y calidad humana a su servicio</p>
        </div>
        <div className="slide slide-two" />
        <div className="slide slide-three">
          <p>Ahora TODOS nuestros audífonos son RECARGABLES. Adiós a las pilas</p>
        </div>
        <div className="slide slide-four">
          <p>Oír mejor es vivir mejor</p>
        </div>
      </Carousel>
  </div>

export default Slides;
