import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Images
import cv from './../images/hoja-de-vida-juan-sebastian-cuenca-diaz.png'

// Components
import ImageStarkey from '../components/ImageStarkey';
import PageTitle from '../components/PageTitle';

const DrJuan: React.FC = () =>
    <div className="App-Dr-Juan">
      <PageTitle text="Dr. Juan Sebastián Cuenca Díaz" />
      <Container fluid="sm" className="px-0">
        <Row noGutters>
          <Col md className="image-juan-container">
            <div className="image-juan" />
          </Col>
          <Col md>
            <div className="text-block">
              <p>
                Fonoaudiólogo egresado de la Universidad del Rosario, Especialista en
                Audiología de la Escuela Colombiana de Rehabilitación.
              </p>
              <p>
                Apasionado por la audiología, realizando diagnóstico clínico, selección
                y adaptación de audífonos de última generación. 
              </p>
              <p>
                Especialista en manejo del vértigo. Realización de protección auditiva
                para exposición a ruido y agua. Más de 4 años de experiencia.
              </p>
              <ImageStarkey />
              <p className="mt-4">
                Distribuidor autorizado de audífonos de alta tecnología marca Starkey
                Laboratories Inc, con capacitaciones nacionales e internacionales.
              </p>
              <p>
                <a href={cv} target="_blank" rel="noreferrer noopener">Ver hoja de vida</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

export default DrJuan;
