import React from 'react';

interface Props {
  text: string,
}

const PageTitle: React.FC<Props> = (props) =>
  <div className="App-PageTitle">
    <h1>{props.text}</h1>
  </div>

export default PageTitle;
