import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export interface ServiceItem {
  image: string,
  imageClass?: string,
  name: string,
  paragraphs: string[],
}

interface Props {
  image: string,
  imageClass?: string,
  name: string,
  paragraphs: string[],
  reverse: boolean
}

const Service: React.FC<Props> = (props) => {
  const { image, imageClass, name, paragraphs, reverse } = props;

  const imageDiv = (image: string, imageClass: string = "") => (
    <div className={"image image-" + image + " " + imageClass} />
  );

  const textDiv = (name: string, paragraphs: string[]) => (
    <div className="text-block">
      <h1>{name}</h1>
      {paragraphs.map((p, idx) => <p key={idx}>{p}</p>)}
    </div>
  );

  return (
    <div className="App-Service">
      <Row className="d-flex d-sm-flex d-md-none">
        <Col>
          {imageDiv(image, imageClass)}
          <div className="mb-5">{textDiv(name, paragraphs)}</div>
        </Col>
      </Row>
      <Row noGutters className="d-none d-sm-none d-md-flex">
        {reverse ? (
          <>
            <Col md>{textDiv(name, paragraphs)}</Col>
            <Col md>{imageDiv(image, imageClass)}</Col>
          </>
        ) : (
          <>
            <Col md>{imageDiv(image, imageClass)}</Col>
            <Col md>{textDiv(name, paragraphs)}</Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default Service;
