import React from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { NavLink, useLocation } from "react-router-dom";

// Images
import logo from './../images/logo.jpg'

// Constants
import {
  ALL_LOCATIONS,
  ROOT_LOCATION,
  ALINA_LOCATION,
  JUAN_LOCATION,
  EXAMS_LOCATION,
  HEADPHONES_LOCATION,
  PROTECTION_LOCATION,
  VERTIGO_LOCATION,
  CONTACT_LOCATION,
} from './Routes'

const SERVICE_LOCATIONS = [
  ALL_LOCATIONS[EXAMS_LOCATION],
  ALL_LOCATIONS[HEADPHONES_LOCATION],
  ALL_LOCATIONS[PROTECTION_LOCATION],
  ALL_LOCATIONS[VERTIGO_LOCATION],
];

const Navigation: React.FC = () => {
  const location = useLocation();
  const inServicePath = SERVICE_LOCATIONS.map(l => l.path).includes(location.pathname);

  return(
    <div className="App-Navigation">
      <Navbar collapseOnSelect={true} expand="lg">
        <Navbar.Brand href="#home">
          <img
            src={logo}
            className="d-inline-block align-top"
            alt="Cenaudio logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link eventKey='1' as={NavLink} to={ALL_LOCATIONS[ROOT_LOCATION].path} exact={true}>
              {ALL_LOCATIONS[ROOT_LOCATION].name}
            </Nav.Link>
            <Nav.Link eventKey='2' as={NavLink} to={ALL_LOCATIONS[ALINA_LOCATION].path}>
              {ALL_LOCATIONS[ALINA_LOCATION].name}
            </Nav.Link>
            <Nav.Link eventKey='3' as={NavLink} to={ALL_LOCATIONS[JUAN_LOCATION].path}>
              {ALL_LOCATIONS[JUAN_LOCATION].name}
            </Nav.Link>
            <NavDropdown active={inServicePath} title="Servicios" id="collasible-nav-dropdown">
              {SERVICE_LOCATIONS.map((location, idx) => (
                <NavDropdown.Item eventKey={'4.' + idx} key={idx} as={NavLink} to={location.path}>
                  {location.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link eventKey='5' as={NavLink} to={ALL_LOCATIONS[CONTACT_LOCATION].path}>
              {ALL_LOCATIONS[CONTACT_LOCATION].name}
            </Nav.Link>
            <li className="phrase">
              <p>oír mejor es vivir mejor</p>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Navigation;
