import React, { useState } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// Components
import PageTitle from '../components/PageTitle';

const containerStyle = {
  width: '100%',
  height: '400px',
  padding: '0px',
};

const center = {
  lat: 2.9347602,
  lng: -75.2849,
};

const Contact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState(""); 

  return(
    <div className="App-Contact">
      <PageTitle text="¡Visítanos!" />
      <Container fluid className="px-0">
        <Row>
          <Col md>
            <LoadScript
              googleMapsApiKey="AIzaSyAyq0buvgtvq-64YigVhE8w_kWMIDdERIk"
              loadingElement={<p>Cargando</p>}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={17}
              >
                <Marker
                  position={center}
                />
              </GoogleMap>
            </LoadScript>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="title-container">
            <h3>Escríbenos</h3>
          </Col>
        </Row>
        <form className="contact-form" action="https://formspree.io/f/xgerznzj" method="POST">
          <Row>
            <Col md="4">
              <input
                type="text"
                name="nombre"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Nombre"
                required={true}
              />
            </Col>
            <Col md="4">
              <input
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                required={true}
              />
            </Col>
            <Col md="4">
              <input type="text"
                name="telefono"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                placeholder="Teléfono"
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea
                name="mensaje"
                value={message}
                onChange={e => setMessage(e.target.value)}
                required={true}
                placeholder="Mensaje..."
              >
              </textarea>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <input type="submit" value="Enviar" />
            </Col>
          </Row>
          <input type="hidden" name="_subject" value="[contacto sitio web]" />
          <input type="text" name="_gotcha" style={{display: "none"}} />
          <input type="hidden" name="_next" value="/" />
        </form>
      </Container>
    </div>
  );
}

export default Contact;
