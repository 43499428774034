import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Contact from './../pages/Contact';
import DrJuan from './../pages/DrJuan';
import DraAlina from './../pages/DraAlina';
import Exams from './../pages/Exams';
import Headphones from './../pages/Headphones';
import Home from './../pages/Home';
import Protection from './../pages/Protection';
import Vertigo from './../pages/Vertigo';

export const ROOT_LOCATION = 'ROOT_LOCATION';
export const ALINA_LOCATION = 'ALINA_LOCATION';
export const JUAN_LOCATION = 'JUAN_LOCATION';
export const EXAMS_LOCATION = 'EXAMS_LOCATION';
export const HEADPHONES_LOCATION = 'HEADPHONES_LOCATION';
export const PROTECTION_LOCATION = 'PROTECTION_LOCATION';
export const VERTIGO_LOCATION = 'VERTIGO_LOCATION';
export const CONTACT_LOCATION = 'CONTACT_LOCATION';

interface Location {
  name: React.ReactNode | string,
  path: string,
  pageComponent: React.ReactNode,
};

interface AllLocations {
  [key: string]: Location;
};

export const ALL_LOCATIONS: AllLocations = {
  ROOT_LOCATION: {
    name: "Inicio",
    path: "/",
    pageComponent: <Home />,
  },
  ALINA_LOCATION: {
    name: "Dra. Alina Margarita Díaz Stérling",
    path: "/dra-alina",
    pageComponent: <DraAlina />,
  },
  JUAN_LOCATION: {
    name: "Dr. Juan Sebastián Cuenca Díaz",
    path: "/dr-juan",
    pageComponent: <DrJuan />,
  },
  EXAMS_LOCATION: {
    name: "Exámenes auditivos",
    path: "/examenes",
    pageComponent: <Exams />,
  },
  HEADPHONES_LOCATION: {
    name: <span>Prueba, selección y adaptación<br /> de audífonos</span>,
    path: "/audifonos",
    pageComponent: <Headphones />,
  },
  PROTECTION_LOCATION: {
    name: "Protección auditiva",
    path: "/proteccion",
    pageComponent: <Protection />,
  },
  VERTIGO_LOCATION: {
    name: "Manejo del Vértigo",
    path: "/vertigo",
    pageComponent: <Vertigo />,
  },
  CONTACT_LOCATION: {
    name: "Contacto",
    path: "/contacto",
    pageComponent: <Contact />,
  },
};

const Routes: React.FC = () =>
  <Switch>
    {Object.keys(ALL_LOCATIONS).map(location => (
      <Route exact path={ALL_LOCATIONS[location].path} key={location}>
        {ALL_LOCATIONS[location].pageComponent}
      </Route>
    ))}
  </Switch>

export default Routes;
