import React from 'react';

import { BrowserRouter as Router } from "react-router-dom";

// Components
import Footer from './components/Footer';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Routes from './components/Routes';

// Styles
import './App.scss';

const App: React.FC = () =>
  <Router>
    <div className="App">
      <Header />
      <Navigation />
      <Routes />
      <Footer />
    </div>
  </Router>

export default App;
