import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { IoIosCalendar } from "react-icons/io";
import { FaAssistiveListeningSystems, FaRegSmile } from "react-icons/fa";

const Stats: React.FC = () =>
  <div className="App-Stats">
    <Container>
      <Row>
        <Col className="item-container" md>
          <IoIosCalendar />
          <p className="stats-number">+30</p>
          <p className="stats-info">años de experiencia</p>
        </Col>
        <Col className="item-container" md>
          <FaAssistiveListeningSystems />
          <p className="stats-number">+150</p>
          <p className="stats-info">jornadas de salud auditiva</p>
        </Col>
        <Col className="item-container" md>
          <FaRegSmile />
          <p className="stats-number">+21.500</p>
          <p className="stats-info">pacientes felices</p>
        </Col>
      </Row>
    </Container>
  </div>

export default Stats;
