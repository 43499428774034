import React from 'react';

import Container from 'react-bootstrap/Container'

// Components
import PageTitle from '../components/PageTitle';
import Service, { ServiceItem } from '../components/Service';

const Protection: React.FC = () => {
  const protections: ReadonlyArray<ServiceItem> = [
    {
      image: "ruido",
      name: "Exposición a Ruido",
      paragraphs: [
        "Este tipo de protección auditiva es recomendada para las personas que " +
        "en sus labores diarias se encuentran expuestos a altos niveles de " +
        "contaminación auditiva.",
        "Nuestros protectores auditivos hechos a la medida atenúan hasta 55 dB " +
        "(HL) en las frecuencias mas importantes."
      ]
    },
    {
      image: "agua",
      name: "Exposición a Agua",
      paragraphs: [
        "deales para personas que desean disfrutar de actividades acuáticas " +
        "previniendo infecciones recurrentes como otitis y hongos en los oídos. "
      ]
    },
    {
      image: "musicos",
      name: "Músicos",
      paragraphs: [
        "Protectores auditivos 100% digitales que protegen de manera instantánea " +
        "y automática a sus oídos de sonidos fuertes. Y permiten omitir las ondas " +
        "sonoras dañinas y darle paso al sonido puro y las voces."
      ]
    },
  ]

  return(
    <div>
      <PageTitle text="Protección Auditiva" />
      <Container fluid="sm" className="px-0">
        {protections.map((p, idx) => (
          <Service
            key={idx}
            image={p.image}
            name={p.name}
            paragraphs={p.paragraphs}
            reverse={!!(idx % 2)}
          />
        ))}
      </Container>
    </div>
  );
}

export default Protection;
