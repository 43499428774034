import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { 
  IoLocationSharp,
  IoMail,
  IoCallSharp,
  IoLogoFacebook,
  IoLogoWhatsapp
} from "react-icons/io5";

const Copyright: React.FC = () =>
  <div className="App-Copyright">
    <p>
      © Diseñado por <a
        href="https://www.handmadetech.org/"
        rel="noreferrer noopener"
        target="_blank"
      >
        Handmadetech
      </a>
    </p>
  </div>


const Footer: React.FC = () =>
  <div className="App-Footer">
    <Container>
      <Row>
        <Col className="column-container" md>
          <h4 className="brand">Cenaudio</h4>
          <p>
            En Cenaudio somos especialistas en productos y servicios para la salud auditiva.
            Brindamos asesoría y acompañamiento a las personas que presentan algún grado de sordera,
            buscando una óptima solución para mejorar su calidad de vida.
          </p>
          <p className="align-right">- Alina M. Díaz Stérling</p>
        </Col>

        <Col className="column-container" md>
          <h4>Facebook</h4>
          <div className="fb-page-container">
            <div
              className="fb-page"
              data-href="https://www.facebook.com/cenaudio"
              data-tabs="timeline"
              data-width="320"
              data-height="400"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false"
            >
              <blockquote cite="https://www.facebook.com/cenaudio" className="fb-xfbml-parse-ignore" >
                <a href="https://www.facebook.com/cenaudio">Cenaudio - Centro Audiológico y Terapéutico del Huila</a>
              </blockquote>
            </div>
          </div>
        </Col>

        <Col className="column-container" md>
          <h4>Información de contacto</h4>
          <div className="item-container">
            <IoLocationSharp />
            <p>Centro Audiológico y Terapéutico del Huila<br />Cra. 9 No 16-16<br />Neiva (Huila)</p>
          </div>
          <div className="item-container">
            <IoCallSharp />
            <p>8743838 - 8667717</p>
          </div>
          <div className="item-container">
            <IoLogoWhatsapp />
            <p>
              <a href="https://wa.link/lug154" rel="noreferrer noopener" target="_blank">317 383 0241</a>
            </p>
          </div>
          <div className="item-container">
            <IoMail />
            <p><a href="mailto:cenaudio@cenaudio@hotmail.com">cenaudio@hotmail.com</a></p>
          </div>
          <div className="item-container">
            <IoLogoFacebook />
            <p>
              <a
                href="https://www.facebook.com/cenaudio"
                rel="noreferrer noopener"
                target="_blank"
              >
                Síguenos en Facebook
              </a>
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Copyright />
        </Col>
      </Row>
    </Container>
  </div>

export default Footer;
